import { default as forgot_45password8Rmxjqy2emMeta } from "/opt/buildhome/repo/app/pages/auth/forgot-password.vue?macro=true";
import { default as loginJAN4AtPMnQMeta } from "/opt/buildhome/repo/app/pages/auth/login.vue?macro=true";
import { default as otpRd9krYe6dBMeta } from "/opt/buildhome/repo/app/pages/auth/otp.vue?macro=true";
import { default as signupm2WAkMB654Meta } from "/opt/buildhome/repo/app/pages/auth/signup.vue?macro=true";
import { default as verifyNQhTPsfWtSMeta } from "/opt/buildhome/repo/app/pages/auth/verify.vue?macro=true";
import { default as _91path_9315ydpcOoDUMeta } from "/opt/buildhome/repo/app/pages/blog/[path].vue?macro=true";
import { default as indexNHpZLFK5FkMeta } from "/opt/buildhome/repo/app/pages/blog/index.vue?macro=true";
import { default as indexzziRMPTVtHMeta } from "/opt/buildhome/repo/app/pages/chat/index.vue?macro=true";
import { default as _91_46_46_46path_93RNOsk9TUAKMeta } from "/opt/buildhome/repo/app/pages/docs/[...path].vue?macro=true";
import { default as index9xeP2sz8ZcMeta } from "/opt/buildhome/repo/app/pages/index.vue?macro=true";
import { default as _91path_9300jj5PZR1bMeta } from "/opt/buildhome/repo/app/pages/legal/[path].vue?macro=true";
import { default as onboardingZCVIEJXUDAMeta } from "/opt/buildhome/repo/app/pages/onboarding.vue?macro=true";
import { default as pricing9sfYb3g5hgMeta } from "/opt/buildhome/repo/app/pages/pricing.vue?macro=true";
export default [
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45password8Rmxjqy2emMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginJAN4AtPMnQMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: "auth-otp",
    path: "/auth/otp",
    meta: otpRd9krYe6dBMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/otp.vue").then(m => m.default || m)
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupm2WAkMB654Meta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/signup.vue").then(m => m.default || m)
  },
  {
    name: "auth-verify",
    path: "/auth/verify",
    meta: verifyNQhTPsfWtSMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/auth/verify.vue").then(m => m.default || m)
  },
  {
    name: "blog-path",
    path: "/blog/:path()",
    meta: _91path_9315ydpcOoDUMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/blog/[path].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexNHpZLFK5FkMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "chat",
    path: "/chat",
    meta: indexzziRMPTVtHMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/chat/index.vue").then(m => m.default || m)
  },
  {
    name: "docs-path",
    path: "/docs/:path(.*)*",
    meta: _91_46_46_46path_93RNOsk9TUAKMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/docs/[...path].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9xeP2sz8ZcMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "legal-path",
    path: "/legal/:path()",
    meta: _91path_9300jj5PZR1bMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/legal/[path].vue").then(m => m.default || m)
  },
  {
    name: "onboarding",
    path: "/onboarding",
    meta: onboardingZCVIEJXUDAMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/onboarding.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricing9sfYb3g5hgMeta || {},
    component: () => import("/opt/buildhome/repo/app/pages/pricing.vue").then(m => m.default || m)
  }
]
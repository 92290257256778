<script setup lang="ts">
import { ConfigProvider } from 'radix-vue'

const useIdFunction = () => useId()

const titleTemplateDefault = 'ChatPET'
const titleDivider = '|'

const i18nHead = useLocaleHead({
  addSeoAttributes: true,
})

useHead({
  // general seo
  titleTemplate: title =>
    title
      ? `${title} ${titleDivider} ${titleTemplateDefault}`
      : titleTemplateDefault,

  // i18n
  htmlAttrs: {
    lang: i18nHead.value.htmlAttrs!.lang,
  },
  link: [...(i18nHead.value.link || [])],
  meta: [...(i18nHead.value.meta || [])],
})
</script>

<template>
  <ConfigProvider :use-id="useIdFunction">
    <GradientBackgroundWrapper>
      <NuxtLayout>
        <NuxtPage />
      </NuxtLayout>
      <Toaster />
      <NuxtLoadingIndicator color="var(--colors-primary)" />
    </GradientBackgroundWrapper>
  </ConfigProvider>
</template>

<style scoped>
* {
  font-family: 'Nunito', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
</style>
